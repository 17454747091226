/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import Layout from "../components/layout-components/Layout";

/* Data Imports */

import AppConfig from "../AppConfig";

const useStyles = makeStyles((theme) => ({
  illustrationOrganiser: {
    height: "calc(100vh - 4rem)",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "280px",
      width: "280px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "320px",
      width: "320px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "768px",
      width: "768px",
    },
    [theme.breakpoints.only("md")]: {
      height: "1024px",
      width: "1024px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "720px",
      width: "720px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "1080px",
      width: "1080px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "1440px",
      width: "1440px",
    },
  },
}));

const Four0Four = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Layout>
        <div className={classes.illustrationOrganiser}>
          <img
            src="/animations/404.svg"
            alt={AppConfig.imageAltText}
            width={512}
            height={512}
            loading="eager"
            referrerPolicy="no-referrer"
            className={classes.illustrationSizer}
          />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Four0Four;
